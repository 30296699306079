<template>
  <div>
    <topNav title="消息通知" :border="true"></topNav>
    <noneHint :img="hintimg" hint="小可愛暫時無消息哦~~"></noneHint>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hintimg: require("../../static/nonemessage.png")
    };
  }
};
</script>

<style></style>
